import axios from "@/utils/request";
//短视频模块API

// 获取热门视频
export function queryVidList(data) {
  return axios.post(`/media/list`, data);
}

//只有vip视频观看超过一定比例
export function sendValidWatched(mediaId) {
  return axios.post(`/media/validWatched`, {mediaId:mediaId});
}

//视频能否播放检查
export function verifyPlay(data){
  return axios.post(`/media/verifyPlay`, data);
}

//关注/取消关注
export function userCare(data){
  return axios.post(`/care/user`, data);
}

//点赞
export function like(data){
  return axios.post(`/like/media`, data);
}

//获取评论列表
export function getComment(data){
  return axios.post(`/comment/listMain`, data);
}

//发布评论
export function commentPublist(data){
  return axios.post(`/comment/publish`, data);
}

//用户购买视频
export function payVideo(data){
  return axios.post(`/media/buy`, data);
}
//获取标签
export function queryLabels() {
  return axios.get(`/media/libraryTag`);
}

//获取片库
export function queryVideoLibrary(data) {
  return axios.post(`/search/videoLibrary`, data);
}