<template>
  <div class="ver_video" @click="videoClick">
    <div id="dplayer"></div>
  </div>
</template>
<script>
import {mapGetters} from "vuex";
import { sendValidWatched, verifyPlay } from "@/api/shortVideo";
import {Toast} from "vant";
import {addVideoHistory} from "@/utils";
import { optRecord } from "@/utils/optLog.js";
export default {
  props: {
    videoInfo: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  data() {
    return {
      player: null,
      videoPlayStatus: false, //视频播放状态
      wCount: 0, //免费观看次数
      isCan: false, //是否能观看 true 能观看，false不能观看 (只有会员视频才能判断)
      nowDate: new Date(),
      playTimer: null,
      playTime: 0,
      mediaPath: "media/m3u8/",
      firstAddLog: true
    }
  },
  computed: {
    ...mapGetters({
      baseUrl: 'baseUrl',
      videoRoadLine: "videoRoadLine", // 选择的线路
    })
  },
  created() {
    addVideoHistory(this.videoInfo);
  },
  mounted() {
    this.init();
    this.verifyPlay(); // 检测视频是否可以观看
  },
  methods: {
    init() {
      // eslint-disable-next-line no-undef
      const player = (this.player = new DPlayer({
        container: document.getElementById('dplayer'),
        autoplay: true,
        lang: "zh-cn",
        hotkey: true,
        screenshot: false,
        preload: "auto",
        video: {
          type: "hls",
          url: this.getPath(this.videoInfo.videoUrl),
        },
      }));
      player.on("playing", () => {
        this.playing();
        this.videoPlayStatus = true;
        //视频播放隐藏暂停按钮
        this.videoPlayIcon(false);
        if (this.player.video.readyState == 4) {
          this.videoLoading(false);
        }
      });
      player.on('loadedmetadata', () => {
        this.player.video
        .play()
        .then(() => {})
        .catch(() => {
          this.videoLoading(false);
          this.videoPlayIcon(true);
        });
      })
      player.on("loadeddata", () => {
        if (this.videoPlayStatus) {
          //隐藏暂停按钮
          this.videoPlayIcon(false);
          //隐藏loging按钮
          this.videoLoading(false);
        } else {
          //隐藏暂停按钮
          this.videoPlayIcon(true);
          //隐藏loging按钮
          this.videoLoading(false);
        }
      });
      player.on("waiting", () => {
        //视频数据正在加载打开loading
        this.videoLoading(true);
        //隐藏暂停按钮
        this.videoPlayIcon(false);
      });
      player.on("pause", () => {
        this.pauses();
        this.videoPlayStatus = false;
        //视频暂停显示按钮
        this.videoPlayIcon(true);
        this.videoLoading(false);
      });
      player.on("timeupdate", () => {
        if (this.videoPlayStatus) {
          this.videoLoading(false);
          
          if (!this.playCheck()) {
            if (this.firstAddLog) {
              this.firstAddLog = false;
              // console.log('弹窗')
              optRecord(1, this.videoInfo)
            }
            player.pause();
            if (this.videoInfo.isVip) {
              this.$emit('vipBuyShow')
            } else {
              this.$emit('goldBuyShow')
            }
          }
          if (this.firstAddLog && this.videoInfo) {
            // console.log(this.videoInfo, '-----')
            if (this.videoInfo.playTime > 300) {
              // console.log(1)
              if (this.player.video.currentTime > this.videoInfo.playTime * 0.1) {
                // console.log(2)
                this.firstAddLog = false;
                optRecord(1, this.videoInfo);
              }
            } else if (this.player.video.currentTime > 15 ) {
              this.firstAddLog = false;
              optRecord(1, this.videoInfo);
            }
          }
        }
      });
    },
    //当视频滑走的时候调用 用作统计
    async getPlayInfo() {
      this.$Api(sendValidWatched, this.videoInfo.id);
      clearInterval(this.playTimer);
      this.playTimer = null;
      this.playTime = 0;
    },
    playing() {
      if (!this.playTimer) {
        this.playTimer = setInterval(() => {
          this.playTime++;
        }, 1000);
      }
    },
    pauses() {
      clearInterval(this.playTimer);
      this.playTimer = null;
    },
    // 给父类的暂停按钮
    cPauses(){
      this.player.pause();
    },
    getPath(path) {
      if (!path) return "";
      let token = localStorage.getItem("Authorization");
      let baseUrl = sessionStorage.getItem("baseUrl");
      let url = `${baseUrl}${this.mediaPath}${path}?Authorization=${token}&c=${this.videoRoadLine.url}`;
      return url;
    },
    //视频购买成功
    succcess() {
      this.isGoldbuyShow = false;
      this.videoInfo.isBuy = true;
      this.isCan = true;
      this.player.play();
      this.$store.dispatch("user/setWallet");
      Toast("购买成功")
    },
    //检测视频是否能播放
    playCheck() {
      if (this.player.video.currentTime < this.videoInfo.prevtime) {
        return true
      }
      if (this.videoInfo.isVip) {
        //会员视频
        if (this.isCan) {
          return true;
        }
        return false;
      } else {
        // 金币视频
        if (this.videoInfo.isBuy || this.isCan) {
          return true;
        }
        return false;
      }
    },
    // 接口检测该视频能否播放
    async verifyPlay(){
      let req = {
        mediaId: this.videoInfo.id
      }
      let res = await this.$Api(verifyPlay, req);
      if(res.code === 200){
        if(res.data.data.playable || res.data.data.mediaInfo.isBuy){
          this.isCan = true;
        }
        this.wCount = res.data.data.ycLeftFreeNum;
        this.$emit('watchCountData', {wCount: res.data.data.ycLeftFreeNum, isCan: this.isCan})
      }
    },
    //点击视频
    videoClick() {
      //播放转暂停
      if (this.videoPlayStatus && this.player.video.readyState == 4) {
        this.player.pause();
      } else {
        //暂停转播放
        if (this.playCheck() !== true && this.player.video.readyState == 4) {
          this.player.pause();
          if (this.videoInfo.nonVipPrice == 0) {
            this.$emit('vipBuyShow')
          } else {
            this.$emit('goldBuyShow')
          }
        } else {
          this.player.play();
        }
      }
    },
    // 播放按钮
    videoPlayIcon(isPause) {
      var dplayerBezelIcon =
          document.getElementsByClassName("dplayer-bezel-icon")[0];
      if (!dplayerBezelIcon) {
        return;
      }
      if (isPause) {
        dplayerBezelIcon.style.display = "block";
      } else {
        dplayerBezelIcon.style.display = "none";
      }
    },
    // 视频loading
    videoLoading(isLoading) {
      //视频加载成功隐藏loading
      var dplayerLoading = document.getElementsByClassName(
          "diplayer-loading-icon"
      )[0];
      if (!dplayerLoading) {
        return;
      }
      if (isLoading) {
        dplayerLoading.style.display = "block";
      } else {
        dplayerLoading.style.display = "none";
      }
    },
  },
  deactivated(){
    // console.log(document.querySelector("#dplayer"))
    // document.querySelector('.dplayer-bezel-icon').style.display = 'block'
  },
  beforeDestroy() {
    if (this.player) {
      this.getPlayInfo();
      this.player.destroy(); //销毁播放器
    }
  },
}
</script>
<style scoped lang="scss">
.ver_video {
  width: 100%;
  height: 100%;

  #dplayer {
    width: 100%;
    height: 100%;

    /deep/ .dplayer-video-wrap {
      pointer-events: none;
    }

    /deep/ .dplayer-controller {
      bottom: 0 !important;
      align-items: center;
      display: flex;

      .dplayer-icons.dplayer-icons-right {
        display: none;
      }
    }

    /deep/ .dplayer-play-icon, /deep/ .dplayer-volume {
      display: none;
    }

    /deep/ .dplayer-bar-wrap {
      width: calc(100% - 120px);
      left: 60px;
      bottom: 12px;

    }

    /deep/ .dplayer-played {
      background: #ffffff !important;
    }

    /deep/ .dplayer-bar-wrap {
      pointer-events: auto;
    }

    /deep/ .dplayer-notice {
      bottom: 50px;
      left: 170px;
    }

    /deep/ .dplayer-icons {
      left: 0.2rem;
      right: 0.2rem;
    }

    /deep/ .dplayer-dtime {
      position: absolute;
      right: 0;
      visibility: visible;
    }

    /deep/ .dplayer-controller, /deep/ .dplayer-controller-mask {
      pointer-events: none;
    }

    /deep/ .dplayer-time {
      visibility: hidden;
    }

    /deep/ .dplayer-ptime {
      position: absolute;
      left: 0;
      visibility: visible;
    }

    /deep/ .dplayer-mobile-play {
      pointer-events: none;
    }

    /deep/ .dplayer-mobile-play > svg {
      display: none;
    }

    /deep/ .diplayer-loading-icon {
      display: block;
    }

    /deep/ .dplayer-bezel-icon {
      background: url("../../assets/png/play.png") center center no-repeat;
      background-size: 100%;
      width: 40px;
      opacity: 1;
      border-radius: 0;
      display: none;
    }
  }
}
</style>
